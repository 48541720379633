
















































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private type: any;
  @Watch("type", { immediate: true })
  private typeChange() {
    this.detail = this.data[this.type == "left" ? "left_palm" : "right_palm"];
  }
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.detail = this.data[this.type == "left" ? "left_palm" : "right_palm"];
  }
  private detail: any = {
    掌型: {
      掌型: [],
    },
    掌色: {
      掌色: [],
    },

    掌纹: {
      "1线": [],
      "2线": [],
      "3线": [],
      "4线": [],
      "5线": "",
      "6线": [],
      "7线": [],
      "8线": "",
      "9线": "",
      "10线": "",
      "11线": [],
      "12线": "",
      "13线": [],
      "14线": "",
      天庭十字纹: "",
      艮震线: "",
      咽区: [],
      三星高照: "",
      乳腺纹: "",
      血脂邱: "",
      孔子眼: "",
      暴怒指: "",
    },
    手掌分区: {
      巽宫: [],
      离宫: [],
      坤宫: [],
      震宫: [],
      明堂: [],
      兑宫: [],
      艮宫: [],
      坎宫: [],
      乾宫: [],
    },
  };
  private options: any = {
    掌型: [
      "原始型",
      "四方型",
      "竹节型",
      "圆锥型",
      "汤匙型",
      "鼓槌型",
      "柔弱型",
      "胖手",
      "瘦手",
      "硬手",
      "软手",
    ],
    掌色: ["淡红色", "白色", "红色", "肝掌", "紫红色", "黄色", "青色", "黑色"],
    线1: [
      "正常",
      "延长至指缝",
      "延长至巽宫",
      "干扰线",
      "十字纹",
      "井字纹",
      "米字纹",
      "五角星纹",
      "三角形纹",
      "方格纹",
      "岛型纹",
      "环形纹",
      "畸断",
      "羽毛状纹",
    ],
    线2: [
      "正常",
      "起点抬高",
      "起点偏低",
      "过长",
      "下垂",
      "平直",
      "大岛纹",
      "分叉",
      "小岛纹",
      "十字纹",
      "井字纹",
      "米字纹",
      "五角星纹",
      "三角形纹",
      "方格纹",
      "环形纹",
      "畸断",
      "羽毛状纹",
    ],
    线3: [
      "正常",
      "弧度过大",
      "弧度过小",
      "起点偏高",
      "起点偏低",
      "岛纹",
      "十字纹",
      "井字纹",
      "米字纹",
      "五角星纹",
      "三角形纹",
      "方格纹",
      "岛型纹",
      "环形纹",
      "畸断",
      "羽毛状纹",
      "过短",
      "干扰线",
      "护线",
    ],
    线4: ["无", "深长", "浅短", "切入3线", "切入2线", "切入1线"],
    无有: ["无", "有"],
    线6: ["无", "深长", "浅短", "切入1线", "切入2线", "切入3线"],
    线7: ["无", "有", "切入1线", "未切入1线"],
    线11: [
      "无",
      "有",
      "短",
      "只有1条",
      "2条",
      "3条",
      "过长",
      "米字纹",
      "干扰线",
    ],
    线12: ["无", "有", "深长", "续断"],
    咽区: [
      "岛纹",
      "十字纹",
      "井字纹",
      "米字纹",
      "五角星纹",
      "三角形纹",
      "方格纹",
      "环形纹",
    ],
    宫: [
      "正常",
      "淡红",
      "淡白",
      "红",
      "紫",
      "青",
      "黑",
      "十字纹",
      "井字纹",
      "米字纹",
      "五角星纹",
      "三角形纹",
      "方格纹",
      "岛型纹",
      "环形纹",
    ],
  };
  private formatTooltip(val: any) {
    return val + "度";
  }
  private valueChange() {
    const d = JSON.parse(JSON.stringify(this.data));
    d[this.type == "left" ? "left_palm" : "right_palm"]["黑睛"] = this.detail;
    this.$emit("change", d);
  }
}
